/* src/components/ChatMessage.css */
.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9; /* Default background */
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc; /* Added border for clarity */
  position: relative;
}

.chat-message.current-user {
  background-color: #e0f7fa; /* Background color for current user messages */
}

.chat-message-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  color: #888;
}

.chat-message-sender {
  font-weight: bold;
}

.chat-message-time {
  font-weight: normal; /* Ensure timestamp isn't bold */
}

.chat-message-text {
  margin-top: 5px;
}

/* Styling <pre> and <code> elements */
.chat-message-text pre,
.chat-message-text code {
  font-family: 'Courier New', Courier, monospace; /* Consistent font for code */
  background-color: #f5f5f5;
  padding: 0;
  border-radius: 3px;
  margin: 0; /* Remove the margin for consistent spacing */
  line-height: 1.2; /* Adjust line-height for better readability */
  overflow-x: auto; /* Allow horizontal scrolling */
  position: relative;
}

/* Specifically targeting inline code elements */
.chat-message-text code {
  vertical-align: baseline; /* Align the text correctly */
  background-color: #f5f5f5;
}

/* Add a header inside the code block */
.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #444; /* Dark background for the header */
  color: #fff; /* Light text color for contrast */
  padding: 5px 10px;
  margin-bottom: 0; /* Remove any margin below the header */
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.code-block {
  background-color: #f5f5f5;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 10px;
}

.code-content {
}

/* Removing margin from <pre> within .code-content */
.code-content pre {
  margin: 0 !important; /* Force remove any margin applied by third-party libraries */
}

/* Styling the filename */
.filename {
  font-size: 0.9em;
  font-weight: bold;
  margin-right: auto; /* Ensure it aligns to the left */
}

/* Styling the copy button */
.copy-button {
  background: transparent;
  width: auto;
  margin: 0 0 0 auto;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #fff; /* White color for the button icon */
}

/* Ensuring the button does not extend */
.copy-button i {
  font-size: 14px; /* Set a fixed size for the icon */
}

.copy-button:hover i {
  color: #ccc; /* Lighter color on hover */
}

/* Styling the copy success message */
.copy-success {
  position: absolute;
  top: -20px;
  right: 5px;
  font-size: 12px;
  color: green;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
}

.suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}

/* Styling message status indicators */
.chat-message-status {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 0.7em;
  color: grey;
}

.chat-message-status.error {
  color: red;
}