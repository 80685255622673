.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.create-chat-modal {
  position: relative;
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.create-chat-modal {
  width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.create-chat-modal h2 {
  margin-bottom: 20px;
}

.create-chat-modal form {
  display: flex;
  flex-direction: column;
}

.create-chat-modal label {
  margin-bottom: 10px;
}

.create-chat-modal input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

.participants-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.modal-actions button {
  padding: 5px 10px;
}