.personas-modal {
  max-width: 800px;
  max-height: 80vh;
  overflow-y: auto;
}

.personas-list {
  margin-bottom: 20px;
}

.personas-list table {
  width: 100%;
  border-collapse: collapse;
}

.personas-list th, .personas-list td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.personas-list tr:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

form label {
  display: block;
  margin-top: 10px;
}

form input, form select, form textarea {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
}

form textarea {
  height: 100px;
}

.modal-actions {
  margin-top: 20px;
}