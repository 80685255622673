/* src/pages/ConfirmSignUp.css */
.auth-form {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
}

.auth-form h1 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #05347e;
  color: white;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.secondary-button {
  margin-top: 10px;
  background-color: #f0f0f0;
  color: #333;
}

.error-message {
  color: #d32f2f;
  background-color: #fde8e8;
  border: 1px solid #fad2d2;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.success-message {
  color: #388e3c;
  background-color: #e8f5e9;
  border: 1px solid #c8e6c9;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}