/* App.css */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
  margin-top: 50px;
  height: calc(100vh - 50px);
  width: 100%;
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}


.auth-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  margin-top: 0;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 30px;
}

.auth-page .logo {
  width: 200px;
  height: 80px;
  background-image: url("jibbit-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}

.auth-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.auth-form h1 {
  color: #333;
  margin-bottom: 2rem;
}

.auth-form .form-group {
  margin-bottom: 1rem;
}

.auth-form input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.auth-form button {
  width: 100%;
  padding: 0.75rem;
  margin-top: 2rem;
  background-color: #05347e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-form button:hover {
  background-color: #50627e;
}

.auth-form button.secondary-button {
  margin-top: 10px;
  background-color: #aaa;
}

.auth-form button.secondary-button:hover {
  background-color: #50627e;
}

.auth-form .error-message {
  color: #d32f2f;
  background-color: #fde8e8;
  border: 1px solid #fad2d2;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .auth-form {
    padding: 1rem;
  }

  .auth-page .logo {
    width: 150px;
    height: 60px;
  }
}