.participant-bubble {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #aaa;
  border-radius: 50px;
  font-size: 14px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-button {
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: #ccc;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  height: auto;
  padding: 0 0 0 5px;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
}

.suggestion-item {
  padding: 5px 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
}
