/* ChatWindow.css */
.chat-window {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 250px);
}

.chat-header {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #f1f1f1;
}

.chat-participants {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.chat-participants-label {
  font-weight: bold;
  color: #8c8c8c;
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #f1f1f1;
}

.message-textarea {
  resize: none;
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  min-height: 60px;
  max-height: 200px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1em;
  flex: 1;
}

.send-button {
  margin-left: 10px;
  width: auto;
  padding: 5px 10px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.send-button:hover {
  background-color: #0064d2;
}

@media (max-width: 768px) {
  .chat-window {
    width: 100%; /* Full width on smaller screens */
  }
}