.user-menu {
  position: absolute;
  top: 50px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.user-menu a,
.user-menu button {
  margin: 0;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.user-menu a:hover,
.user-menu button:hover {
  background-color: #f1f1f1;
}
