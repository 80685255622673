.autocomplete-input {
  display: inline-block;
  position: relative;
}

.autocomplete-input-field {
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  margin-left: 5px;
  min-width: 300px;
  width: auto;
}

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  width: 200px;
}

.autocomplete-suggestions li {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-suggestions li:hover {
  background-color: #f0f0f0;
}
