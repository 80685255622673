/* Sidebar.css */
.sidebar {
  width: 250px;
  background-color: #eaeaea;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
}

.chat-list {
  padding: 10px;
}

.chat-item {
  display: block;
  padding: 10px;
  cursor: pointer;
  color: grey;
  margin-left: 10px;
  transition: background-color 0.3s, color 0.3s;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.chat-item:hover {
  background-color: #ccc;
}

.chat-item.active {
  background-color: #d1d1d1;
  color: grey;
}

.add-chat-button {
  width: 30px;
  margin: 0px 0px 0px auto;
  padding: 0px;
  font-size: 1.5em;
  background-color: #aaa;
}

.add-chat-button:hover {
  background-color: #666;
}

.chat-list-header {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: #f1f1f1;
}

.chat-list-header-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 32px;
}

.chat-list-label-text {
  display: block;
  font-weight: bold;
  color: #8c8c8c;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
  }
}