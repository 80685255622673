/* src/pages/SignUp.css */
.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}

.signup-logo {
  width: 200px;
  height: 80px;
  background-image: url("../jibbit-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}

.signup-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

h1 {
  color: #333;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1rem;
}

input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 0.75rem;
  margin-top: 2rem;
  background-color: #05347e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #50627e;
}

.error-message {
  color: #d32f2f;
  background-color: #fde8e8;
  border: 1px solid #fad2d2;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

@media (max-width: 480px) {
  .signup-form {
    padding: 1rem;
  }

  .signup-logo {
    width: 150px;
    height: 60px;
  }
}