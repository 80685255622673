/* Header.css */
.jibbit-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f1f1f1;
  box-sizing: border-box;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 30px;
  width: 138px;
  background-image: url("../jibbit-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.user-icon-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40px; /* Adjust as needed */
}

.user-icon {
  font-size: 1.5em;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.user-icon:hover {
  transform: scale(1.1);
}

.user-icon:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .jibbit-header {
    padding: 5px;
  }
  
  .logo {
    width: 100px;
  }
}